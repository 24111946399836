import React, { useState, useEffect } from 'react';
import { getTasks, addTask } from './api';
import ReactWordcloud from 'react-wordcloud';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import './styles/wordcloud.css';
import './styles/styles.css';

function App() {
    const [tasks, setTasks] = useState([]);
    const [text, setText] = useState('');
    const [wordCloudData, setWordCloudData] = useState([]);
    const [sentimentMessage, setSentimentMessage] = useState('');
    const [sentimentScore, setSentimentScore] = useState(null);
    const [sentimentComponents, setSentimentComponents] = useState({});

    useEffect(() => {
        async function fetchTasks() {
            try {
                const tasks = await getTasks();
                setTasks(tasks);
                updateWordCloudData(tasks);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        }
        fetchTasks();
    }, []);

    const handleAddTask = async () => {
        try {
            const newTask = await addTask(text);
            const updatedTasks = [...tasks, newTask];
            setTasks(updatedTasks);
            setText('');
            updateWordCloudData(updatedTasks);
            updateSentimentData(newTask);
        } catch (error) {
            console.error('Error adding task:', error);
        }
    };

    const updateWordCloudData = (tasks) => {
        const words = tasks.flatMap(task => task.words);
        const wordCounts = words.reduce((acc, word) => {
            acc[word] = (acc[word] || 0) + 1;
            return acc;
        }, {});
        const wordCloudData = Object.keys(wordCounts).map(word => ({ text: word, value: wordCounts[word] }));
        setWordCloudData(wordCloudData);
    };

    const updateSentimentData = (task) => {
        const { pos, neg, neu, compound } = task.sentiment;
        setSentimentMessage(`The text you entered is largely ${compound >= 5 ? 'positive' : compound <= -5 ? 'negative' : 'neutral'}`);
        setSentimentScore(`Positive: ${pos.toFixed(2)}%, Negative: ${neg.toFixed(2)}%, Neutral: ${neu.toFixed(2)}%, Compound: ${compound.toFixed(2)}%`);
        setSentimentComponents({
            labels: ['Positive', 'Negative', 'Neutral', 'Overall Sentiment'],
            datasets: [
                {
                    label: 'Sentiment Scores (%)',
                    data: [pos, neg, neu, compound],
                    backgroundColor: ['#2196f3', '#f44336', '#ffeb3b', '#4caf50'],
                },
            ],
        });
    };

    return (
        <div className="container">
            <div className="task-manager">
                <h1>Task Manager</h1>
                <textarea value={text} onChange={(e) => setText(e.target.value)} />
                <button onClick={handleAddTask}>Add Task</button>
                <div className="task-list">
                    <h2>Tasks</h2>
                    <div className="word-cloud-container">
                        <h4>Word Cloud</h4>
                        <div className="word-cloud">
                            <ReactWordcloud words={wordCloudData} />
                        </div>
                    </div>
                    {sentimentMessage && (
                        <div className="sentiment-message">
                            <h2>{sentimentMessage}</h2>
                            <p>Overall Sentiment Score: {sentimentScore}</p>
                            <div className="sentiment-chart">
                                <Bar data={sentimentComponents} />
                            </div>
                        </div>
                    )}
                    <table>
                        <thead>
                            <tr>
                                <th>Text</th>
                                <th>Sentiment</th>
                                <th>Sentences</th>
                                <th>Words</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks.map((task, index) => (
                                <tr key={index} className="task">
                                    <td>{task.text}</td>
                                    <td>{`Positive: ${task.sentiment.pos.toFixed(2)}%, Negative: ${task.sentiment.neg.toFixed(2)}%, Neutral: ${task.sentiment.neu.toFixed(2)}%, Compound: ${task.sentiment.compound.toFixed(2)}%`}</td>
                                    <td>{task.sentences.join(' | ')}</td>
                                    <td>{task.words.join(', ')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default App;

